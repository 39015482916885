/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import Service, { AFLORA, AMOSTRAS, RECMIN, VALIDACAO, PETRO, GEOCRON } from "../../../../../service/Service";
import { AtividadeValidacaoModal } from "../AtividadeValidacaoModal";
import { MotivoReavaliacaoModal } from "../MotivoReavaliacaoModal";
import { useState, useCallback, useMemo } from "react";
import { swalErro, swalWarning } from "../../../../../util/validadores";
import swal from "sweetalert";

import { AfloramentoAprovacao } from "../DetalhesAtividades/AfloramentoAprovacao";
import { RecursoMineralAprovacao } from "../DetalhesAtividades/RecursoMineralAprovacao";
import { AmostrasAprovacao } from "../DetalhesAtividades/AmostrasAprovacao";
import { PetrografiasAprovacao } from "../DetalhesAtividades/PetrografiaAprovacao";
import { GeocronologiaAprovacao } from "../DetalhesAtividades/GeocronologiaAprovacao";

import {
  TIPO_ATIVIDADE_AFLORAMENTO,
  TIPO_ATIVIDADE_AMOSTRAS,
  TIPO_ATIVIDADE_RECURSO_MINERAL,
  TIPO_ATIVIDADE_PETROGRAFIA,
  TIPO_ATIVIDADE_GEOCRONOLOGIA
} from "../../../../../util/constantes";
import { mostrarNotificacao } from "../../../../../reducers/notificacaoReducer";
import { AGUARDANDO_REVISAO, AGUARDANDO_VALIDACAO } from "../../../utils/constantes/StatusValidacaoPublicacao";
import { useDispatch } from "react-redux";


const exibirSwalErroBibliotecas = (
  setIdTipoAtividadeSelecionada
) => {
  return (err) => {
    console.error("Erro ao recuperar bibliotecas:", err);
    setIdTipoAtividadeSelecionada(null);
    swalErro({
      title: "Não foi possível carregar os dados do módulo selecionado",
    });
  };
};

const AprovacaoAtividade = ({
  atividadeSelecionada,
  exibirModal,
  setExibirModal,
  isRevisorProjeto,
  isValidadorProjeto,
  setAtualizarTabelaAtividades
}) => {
  const [atividadeEmAprovacao, setAtividadeEmAprovacao] = useState(null);
  const [idAtividadeAprovacao,] = useState(atividadeSelecionada.id);
  const [idStatusStividadeSelecionada,] = useState(atividadeSelecionada.idStatusValidacao)
  const [idTipoAtividadeSelecionada, setIdTipoAtividadeSelecionada] = useState(atividadeSelecionada.tipo);
  const [exibirModalReavaliacao, setExibirModalReavaliacao] = useState(false);
  const [habilitarToolbarAprovacao,] = useState(() => (
    (isRevisorProjeto || isValidadorProjeto) && (atividadeSelecionada.idStatusValidacao === AGUARDANDO_VALIDACAO.id || atividadeSelecionada.idStatusValidacao === AGUARDANDO_REVISAO.id) 
  ));

  const dispatch = useDispatch();

  //#region funções de exibição de atividades
  const exibirAfloramento = () => {
    Service('/afloramentos', AFLORA).get(idAtividadeAprovacao)
      .then(async ({ data: { dados: atividade } }) => {
        setAtividadeEmAprovacao(atividade);
        setIdTipoAtividadeSelecionada(TIPO_ATIVIDADE_AFLORAMENTO.id);
      }).catch(exibirSwalErroBibliotecas(setIdTipoAtividadeSelecionada));
  };

  const exibirRecursoMineral = () => {
    Service('/recursoMineral', RECMIN).get(idAtividadeAprovacao)
      .then(async ({ data: { dados: atividade } }) => {
        setAtividadeEmAprovacao(atividade);
        setIdTipoAtividadeSelecionada(TIPO_ATIVIDADE_RECURSO_MINERAL.id);
      }).catch(exibirSwalErroBibliotecas(setIdTipoAtividadeSelecionada));
  };

  const exibirAmostras = () => {
    Service('/cadastroAmostra', AMOSTRAS).get(idAtividadeAprovacao)
      .then(({ data: { dados: atividade } }) => {
        atividade.tipo = 'CADASTRO_AMOSTRA';
        atividade.nomeTipo = 'Cadastrar amostras';
        let existentes = [];
        if (atividade.amostras) {
          // eslint-disable-next-line array-callback-return
          atividade.amostras.map(amostra => {
            existentes.push(amostra);
          })
          atividade.amostras = {
            existentes: existentes ?? []
          }
        } else {
          atividade.amostras = {
            existentes: []
          }
        }
        setAtividadeEmAprovacao(atividade);
        setIdTipoAtividadeSelecionada(TIPO_ATIVIDADE_AMOSTRAS.id);
      });
  };

  const exibirPetrografias = () => {
    Service('/amostras/atividade', PETRO).get(idAtividadeAprovacao)
      .then(async ({ data: { dados: atividade } }) => {
        setAtividadeEmAprovacao(atividade);
        setIdTipoAtividadeSelecionada(TIPO_ATIVIDADE_PETROGRAFIA.id);
      }).catch(exibirSwalErroBibliotecas(setIdTipoAtividadeSelecionada));
  };

  const exibirGeocronologias = () => {
    Service('/geocronologias', GEOCRON).get(idAtividadeAprovacao)
      .then(async ({ data: { dados: atividade } }) => {
        setAtividadeEmAprovacao(atividade);
        setIdTipoAtividadeSelecionada(TIPO_ATIVIDADE_GEOCRONOLOGIA.id);
      }).catch(exibirSwalErroBibliotecas(setIdTipoAtividadeSelecionada));
  };


  const carregarAtividade = () => {
    switch (idTipoAtividadeSelecionada) {
      case TIPO_ATIVIDADE_AFLORAMENTO.id:
        exibirAfloramento();
        break;
      case TIPO_ATIVIDADE_AMOSTRAS.id:
        exibirAmostras();
        break;
      case TIPO_ATIVIDADE_PETROGRAFIA.id:
        exibirPetrografias();
        break;
      case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
        exibirRecursoMineral();
        break;
      case TIPO_ATIVIDADE_GEOCRONOLOGIA.id:
        exibirGeocronologias();
        break;
      default:
        break;
    }
  };

  //#endregion

  //#endregion

  //#region  funções de aprovação/reavaliação
  //#region  funções de aprovação
  const aoAprovarAtividade = (url, mensagemSucesso) => {
    Service(url, VALIDACAO).post()
      .then(() => {
        dispatch(
          mostrarNotificacao(mensagemSucesso, { tipo: 'success' }, true)
        );
        setExibirModal(false);
        setAtualizarTabelaAtividades(true);
      },).catch(
        (error) => {
          swalWarning({ title:error.response.data.erros[0] });
        }
      );
  }

  const aoAprovar = useCallback(async() => {
    if (idStatusStividadeSelecionada === AGUARDANDO_REVISAO.id && !isRevisorProjeto) {
      swalWarning({ title:"Usuário não possui permissão de revisor."});
      return
    }

    if (idStatusStividadeSelecionada === AGUARDANDO_VALIDACAO.id && !isValidadorProjeto) {
      swalWarning({ title:"Usuário não possui permissão de validador."});
      return
    }
    const result = await swal({
      title: `Tem certeza que deseja Aprovar a atividade?`,
      icon: "info",
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
      },
    });

    if (!result) {
      return
    };

    if (idStatusStividadeSelecionada === AGUARDANDO_REVISAO.id) {
      aoAprovarAtividade(`/atividades/validacao/envioAprovacaoRevisao/${idAtividadeAprovacao}`, "Revisão realizada com sucesso.");
      return;
    }

    if (idStatusStividadeSelecionada === AGUARDANDO_VALIDACAO.id) {
      aoAprovarAtividade(`/atividades/validacao/envioAprovacaoValidacao/${idAtividadeAprovacao}`, "Validação realizada com sucesso.");
      return;
    }
    dispatch(
      mostrarNotificacao("Status da atividade não permite validação/revisão!")
    );
  }, [idStatusStividadeSelecionada]);
  //#endregion

  //#region funções de reavaliação de validação
  const aoReavaliarAtividade = (url, payloadReavaliacao, mensagemSucesso) => {
    Service(url, VALIDACAO).post(payloadReavaliacao)
      .then(() => {
        dispatch(
          mostrarNotificacao(mensagemSucesso, { tipo: 'success' }, true)
        );
        setExibirModal(false);
        setAtualizarTabelaAtividades(true);
      }).catch(
        (error) => {
          const errorMessage = error.response?.data?.erros?.[0];
          if (errorMessage.includes("e-mail")) {
              dispatch(
                mostrarNotificacao(mensagemSucesso, { tipo: 'success' }, true)
              );
              setExibirModal(false);
              setAtualizarTabelaAtividades(true);
              swalErro({ title: errorMessage });
          }else{
            swalErro({ title: errorMessage });
          }
        }
      );
  }

  const aoEnviarReavaliacao = useCallback(async(form) => {
    const result = await swal({
      title: `Tem certeza que deseja Reavaliar a atividade?`,
      icon: "info",
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
      },
    });

    if (!result) {
      return
    };

    const motivoReavaliacao = form.motivoReavaliacao;
    const payload = {
      idAtividade: idAtividadeAprovacao,
      motivoReavaliacao: motivoReavaliacao
    }

    if (idStatusStividadeSelecionada === AGUARDANDO_REVISAO.id) {
      aoReavaliarAtividade('/atividades/validacao/envioReavaliacaoRevisao', payload, 'Solicitação de correção de atividade enviada com sucesso.');
      return;
    }

    if (idStatusStividadeSelecionada === AGUARDANDO_VALIDACAO.id) {
      aoReavaliarAtividade('/atividades/validacao/envioReavaliacaoValidacao', payload, 'Solicitação de correção de atividade enviada com sucesso.');
      return;
    }
    dispatch(
      mostrarNotificacao("Status da atividade não permite validação/revisão!")
    );

  }, [dispatch, idAtividadeAprovacao, idStatusStividadeSelecionada, setExibirModal]);
  //#endregion
  //#endregion

  const Modal = useCallback(() => {
    if (!atividadeEmAprovacao || atividadeEmAprovacao === null) {
      carregarAtividade();
    }
    if (!exibirModal || !atividadeEmAprovacao)
      return <></>
    switch (idTipoAtividadeSelecionada) {
      case TIPO_ATIVIDADE_AFLORAMENTO.id:
        return <ExibicaoModal
          titulo="Afloramento"
          componenteModal={
            <AfloramentoAprovacao atividade={atividadeEmAprovacao} />
          } />

      case TIPO_ATIVIDADE_AMOSTRAS.id:
        return <ExibicaoModal
          titulo="Cadastro de Amostras"
          componenteModal={
            <AmostrasAprovacao atividade={atividadeEmAprovacao} />
          } />
      case TIPO_ATIVIDADE_PETROGRAFIA.id:
        return <ExibicaoModal
                  titulo="Cadastrar análise petrográfica"
                  componenteModal={
                    <PetrografiasAprovacao amostra={atividadeEmAprovacao} />
                  } />
      case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
        return <ExibicaoModal
          titulo="Recurso Mineral"
          componenteModal={
            <RecursoMineralAprovacao atividade={atividadeEmAprovacao} />
          } />

      case TIPO_ATIVIDADE_GEOCRONOLOGIA.id:
        return <ExibicaoModal
          titulo="Geocronologia"
          componenteModal={
            <GeocronologiaAprovacao atividade={atividadeEmAprovacao} />
          } />
      default:
        return <></>
    }
  }, [atividadeEmAprovacao, idTipoAtividadeSelecionada]);

  const ModalReavaliacao = useCallback(() => {
    return <MotivoReavaliacaoModal
      aoEnviar={aoEnviarReavaliacao}
      aberto={exibirModalReavaliacao === true}
      aoFechar={() => setExibirModalReavaliacao(false)}
      key={"modalReavaliacaoAtividade"}
    />
  }, [exibirModalReavaliacao]);

  const mostrarModalReavaliacao = () => {
    if (idStatusStividadeSelecionada === AGUARDANDO_REVISAO.id && !isRevisorProjeto) {
      swalWarning({ title:"Usuário não possui permissão de revisor."});
      return
    }

    if (idStatusStividadeSelecionada === AGUARDANDO_VALIDACAO.id && !isValidadorProjeto) {
      swalWarning({ title:"Usuário não possui permissão de validador."});
      return
    }
  
    setExibirModalReavaliacao(true);
    
    
  }

  const ExibicaoModal = useCallback(({ titulo, componenteModal }) => {
    return <>
      <AtividadeValidacaoModal
        titulo={titulo}
        tamanho="xl"
        habilitarToolbarAprovacao={habilitarToolbarAprovacao}
        aberto={exibirModal === true}
        aoAprovar={aoAprovar}
        aoReavaliar={mostrarModalReavaliacao}
        aoFechar={() => {
          setExibirModal(false);
        }
        }
        componenteAtividade={componenteModal} />
    </>
  }, [
    habilitarToolbarAprovacao,
    exibirModal,
    setExibirModal,
    aoAprovar,
    mostrarModalReavaliacao
  ]);

  return (<>
    <ModalReavaliacao />
    <Modal />
  </>)


}

AprovacaoAtividade.propTypes = {
  atividade: PropTypes.object
}

export { AprovacaoAtividade };