/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { createRef, useCallback, useEffect, useState } from "react";
import DropdownListWithValue2 from "../../Jqx/DropdownList/DropdownListWithValue2";
import Select, { createFilter } from "react-select";
import Erro from "../../Erro";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import { Checkbox } from "semantic-ui-react";



const DropdownListFieldWithCheckbox = ({
  input,
  meta,
  customOnChange,
  isClearable = true,
  sizelimitForSelection = 0,
  selectAllLabel=null,
  ...props
}) => {
  const [elementosFormatados, setElementosFormatados] = useState([]);
  const [valorSelecionado, setValorSelecionado] = useState([]);
  const selecioneTodosOpcao = { value: 0, label:  selectAllLabel || "Selecione todos..." };
  const mensagemErro = meta.touched && !meta.valid && meta.error;
  const fieldRef = createRef();

  const multSelectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (base) => ({
      ...base,
      minHeight: 25,
      height: 25,
      fontFamily: "Helvetica",
      backgroundColor: "white",
      color: props.disabled ? "#dbdbdb" : "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      height: 23,
      minHeight: 0,
      padding: 0,
      margin: 0,
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: 13,
      padding: 0,
      margin: 0,
      paddingLeft: 5,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "90%",
      display: "initial",
    }),
    container: (base) => ({
      ...base,
      padding: 0,
    }),

    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 23,
      maxHeight: 23,
    }),
    option: (base) => ({
      ...base,
      ":hover": {
        backgroundColor: "#DEEBFF",
      },
      color: "black",
      backgroundColor: "#FFF",
      fontSize: 13,
      fontFamily: "Helvetica",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: 13,
      fontFamily: "Helvetica",
      marginLeft: 2,
      display: "initial",
      padding: "0px, 5px, 0px, 0px",
      paddingBottom: "5px !important",
      marginTop: "-2px !important",
    }),

    input: (base) => ({
      ...base,
      margin: 0,
      marginLeft: "5px",
      overflow: "none",
      fontSize: 13,
      fontFamily: "Helvetica",
      display: "initial",
      padding: "0px, 5px, 0px, 0px",
    }),
  };

  const multiValueContainer = ({ selectProps, data }) => {
    if (data.value === 0) return "";
    const label = data.label;
    const allSelected = selectProps.value.filter(
      (option) => option.value !== 0
    );
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";

    const val = `${label}${labelSuffix}`;
    return val;
  };

  useEffect(() => {
    let tempElementosFormatados = [];
    let defaultDisplay = "nome";
    if (props.displayMember !== "") {
      defaultDisplay = props.displayMember;
    }

    if (!props.elementos || props?.elementos?.length === 0) {
      setElementosFormatados([]);
    } else {
      if (props.elementos && Array.isArray(props.elementos)) {
        props.elementos?.forEach((item) => {
          tempElementosFormatados.push({
            value: item.id,
            label: item[defaultDisplay],
          });
        });
        tempElementosFormatados.length > 1 &&
          tempElementosFormatados.unshift(selecioneTodosOpcao);
        setElementosFormatados(tempElementosFormatados);
      }
    }
  }, [props?.elementos]);

  useEffect(() => {
    if (!input.value || !input.value?.length) return setValorSelecionado([]);

    let tempArray = [];

    input.value.forEach((valor) => {
      elementosFormatados.forEach((elementoFormatado) => {
        if (elementoFormatado.value === valor) {
          tempArray.push(elementoFormatado);
        }
      });
    });

    const tempArraysValues = tempArray.map((item) => item.value);
    const optionsValues = elementosFormatados
      .map((item) => item.value)
      ?.slice(1);

    const todosSelecionados = optionsValues.every((value) =>
      tempArraysValues.includes(value)
    );

    setValorSelecionado(
      todosSelecionados ? [selecioneTodosOpcao, ...tempArray] : tempArray
    );
  }, [elementosFormatados, input.value]);

  const onChange = useCallback(
    (onChangeProps) => {
      if (onChangeProps === null) {
        input?.onChange?.([]);
        setValorSelecionado([]);
      } else {
        const isSelectAll = !!onChangeProps.find((valor) => valor.value === 0);
        const unSelectAll =
          !isSelectAll &&
          !!valorSelecionado?.find((valor) => valor.value === 0);

        let valores = [];
        if (
          isSelectAll &&
          !!!valorSelecionado?.find((valor) => valor.value === 0)
        ) {
          valores = elementosFormatados;
        } else if (unSelectAll) {
          valores = [];
        } else {
          valores = onChangeProps;
        }
        setValorSelecionado(valores);
        input?.onChange?.(
          valores?.map((item) => item.value).filter((value) => value !== 0)
        );
      }
    },
    [input, elementosFormatados, valorSelecionado]
  );

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any",
  };

  const optionLabel = (option) => {
    const checked = valorSelecionado?.length
      ? !!valorSelecionado.find((valor) => valor.value === option.value)
      : false;
    return (
      <div
        className="d-flex align-items-center pointer"
        style={{ background: "transparent", color: checked?'red':'' }}
      >
        <Checkbox checked={checked} />
        <span className="ml-2">{option.label}</span>
      </div>
    );
  };
  return (
    <>
      {
        <>
          {props.label && (
            <Form.Label className="small">
              {props.label}
              {props.required && <span style={{ color: "red" }}>*</span>}
              {props.label && props.dica && <Hint textoAjuda={props.dica} />}
            </Form.Label>
          )}
          <Select
            key={`dropDownKey-${props.label ?? ""}`}
            className="select-personalizado"
            ref={fieldRef}
            filterOption={createFilter(filterConfig)}
            hideSelectedOptions={false}
            getOptionLabel={optionLabel}
            closeMenuOnSelect={false}
            components={{ MultiValueContainer: multiValueContainer }}
            noOptionsMessage={() => "Nenhuma opção encontrada."}
            placeholder={props.placeholder ?? "Selecione.."}
            isMulti
            styles={multSelectStyles}
            isDisabled={props.disabled}
            value={valorSelecionado}
            isClearable={isClearable}
            isSearchable={true}
            onChange={onChange}
            options={elementosFormatados}
          />
          <Erro mensagem={mensagemErro} />
        </>
      }
    </>
  );
};

DropdownListFieldWithCheckbox.propTypes = {
  ...DropdownListWithValue2.propTypes,
};

DropdownListFieldWithCheckbox.defaultProps = {
  ...DropdownListWithValue2.defaultProps,
};

export default DropdownListFieldWithCheckbox;
