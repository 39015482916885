import dayjs from "dayjs";
import { FormApi } from "final-form";
import { useCallback, useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Card, Col, Row } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import { DateTimeInputField } from "../../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../../components/field/DropdownListField";
import { resolverExibidos } from "../../../../../../components/SubForm/SubForm";
import { forcarLimparComboBox } from "../../../../utils/gerais";
import {
  consultarClassesRocha,
  consultarComplementosRocha,
  consultarPrefixosRocha,
  consultarRochaPetrografia,
  consultarRochas,
  consultarRochasAfloramento,
  consultarSubClassesRocha,
  consultarUnidadeLitoEstratigraficas,
} from "../../services/subFormAmostraService";
import { consultarVisitasPorIdEstacao } from "../../services/visitasService";
import { Amostra } from "../../types/amostra";
import { Visita } from "../../types/geocronologiaTypes";
import { CamposDaParteInferior } from "./CamposDaParteInferior";
import { CamposDaParteSuperior } from "./CamposDaParteSuperior";
import "./styles.css";
import { HiddenField } from "../../../../../../components/field/HiddenField";
import { recuperarInformacoesDoVinculo } from "../../services/geocrologiaServices";
type SubFormAmostraProps = {
  amostraSelecionada: Amostra;
  formRef: { form: FormApi; initialValues: any };
  estaEditando: boolean;
  disabled: boolean;
  estaVisualizando: boolean;
  bbMinerais: { id: number; nome: string }[];
  coletores: { id: number; nome: string }[];
  isChefeProjeto: boolean;
  idUsuarioAtual: number;
};

enum STATUSES_BIBLIOGRAFIA_ENUM {
  N = "N",
  S = "S",
}

enum TIPOS_ASSOCIAÇÃO_ENUM {
  PETROGRAFIA = "PETROGRAFIA",
  AFLORAMENTO = "AFLORAMENTO",
  NENHUM = "NENHUM",
}

const IGNEAS_METAMORFICAS = "IGNEAS_METAMORFICAS";

const prefixoDoFormularioParaAcoplarOsAtributosNumObjeto =
  "informacoesAmostra.";
const prefixoFormularioParaRecuperarOsValoresDoObjeto = "informacoesAmostra";

export const SubFormAmostra = ({
  amostraSelecionada,
  formRef,
  estaEditando = true,
  estaVisualizando = true,
  disabled,
  bbMinerais = [],
  coletores,
  isChefeProjeto,
  idUsuarioAtual
}: SubFormAmostraProps) => {
  const [amostraDeBibliografia, setAmostraDeBibliografia] = useState<
    boolean | null
  >(null);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [classesRocha, setClassesRocha] = useState<{ id: number }[]>([]);
  const [subClassesRocha, setSubClassesRocha] = useState<{ id: number }[]>([]);
  const [subClassesRochaCompleto, setSubClassesRochaCompleto] = useState<
    { id: number; idTipoRocha: number }[]
  >([]);
  const [prefixosRocha, setPrefixosRocha] = useState([]);
  const [rochasCompleto, setRochasCompleto] = useState<
    {
      id: number;
      idSubclasseRocha: number;
      idTipoRocha: number;
      rochaAtual: string;
    }[]
  >([]);
  const [rochas, setRochas] = useState<
    { id: number; idSubclasseRocha: number; idTipoRocha: number }[]
  >([]);
  const [complementosRocha, setComplementosRocha] = useState([]);
  const [unidadesLitoEstratigrafica, setUnidadesLitoEstratigrafica] = useState<
    []
  >([]);
  const [idClasseRochaSelecionada, setIdClasseRochaSelecionada] = useState<
    null | number
  >(null);

  const [idSubClasseRochaSelecionada, setIdSubClasseRochaSelecionada] =
    useState<null | number>(null);

  const [idRochaSelecionada, setIdRochaSelecionada] = useState<number | null>(
    null
  );

  const [infoVinculo, setInfoVinculo] = useState(null);

  const [rochaAfloramento, setRochaAfloramento] = useState<{
    idTipoRocha: number;
    idSubClasseRocha: number;
    idRochaNomeada: number;
    idComplemento: number;
    idPrefixo: number;
    mineraisNomenclatura: {
      existentes?: any[] | undefined;
      editadosPorId?: {} | undefined;
      idsExcluidos?: any[] | undefined;
      novos?: any[] | undefined;
    };
  } | null>(null);
  const [visita, setVisita] = useState<Visita | null>();

  const [rochaPetrografia, setRochaPetrografia] = useState<{
    idClasseRocha: number;
    idSubClasseRocha: number;
    idRocha: number;
    idComplemento: number;
    idPrefixo: number;
    dataUltimaAtualizacao: string;
    composicoesMineralogicas: {
      existentes?: any[] | undefined;
      editadosPorId?: {} | undefined;
      idsExcluidos?: any[] | undefined;
      novos?: any[] | undefined;
    };
  } | null>(null);
  const [tipoAssociação, setTipoAssociação] =
    useState<TIPOS_ASSOCIAÇÃO_ENUM | null>(null);
  const [associacao, setAssociacao] = useState<null | {
    dataUltimaAtualizacao: string;
  }>(null);

  const [desabilitado, setDesabilitado] = useState<boolean | null>(null);

  const [idAssociacao, setIdAssociacao] = useState<number | null>(null);

  const [labelEdicao, setLabelEdicao] = useState<string | null>(null);

  const [mostrarLabel, setMostrarLabel] = useState<boolean>(false);

  const setarNomeAssociacao = useCallback(() => {
    if (!tipoAssociação) return;
    formRef.initialValues.informacoesAmostra.nomeAssociacao = tipoAssociação;
    formRef.form.mutators.setValue(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "nomeAssociacao",
      tipoAssociação
    );
  }, [
    formRef.form.mutators,
    formRef.initialValues.informacoesAmostra,
    tipoAssociação,
  ]);

  useEffect(() => {
    if (tipoAssociação && !estaEditando) setarNomeAssociacao();
  }, [tipoAssociação, setarNomeAssociacao, estaEditando]);

  const setarIdAssociacao = useCallback(() => {
    if (!idAssociacao) return;
    formRef.initialValues.informacoesAmostra.idAssociacao = idAssociacao;
    formRef.form.mutators.setValue(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idAssociacao",
      idAssociacao
    );
  }, [formRef.form.mutators, formRef.initialValues, idAssociacao]);

  useEffect(() => {
    if (idAssociacao && !estaEditando) setarIdAssociacao();
    return () => {
      setIdAssociacao(null);
    };
  }, [estaEditando, idAssociacao, setarIdAssociacao]);

  const setarToponimia = useCallback(() => {
    if (!visita?.toponimia) return;
    formRef.initialValues.informacoesAmostra.toponimia = visita?.toponimia;
    formRef.form.mutators.setValue(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "toponimia",
      visita?.toponimia
    );
  }, [
    formRef.form.mutators,
    formRef.initialValues.informacoesAmostra,
    visita?.toponimia,
  ]);

  useEffect(() => {
    if (!visita || estaEditando) return;
    setarToponimia();
  }, [estaEditando, setarToponimia, visita]);

  const montarValores = useCallback(
    (
      idClasseRocha?: number,
      idSubClasseRocha?: number,
      idPrefixoRocha?: number,
      idComplementoRocha?: number,
      idRochaNomeada?: number,
      dataUltimaAtualizacaoVinculo?: string
    ) => {
      formRef.initialValues.informacoesAmostra.idClasseRocha = idClasseRocha;
      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idClasseRocha",
        idClasseRocha
      );

      formRef.initialValues.informacoesAmostra.idSubClasseRocha =
        idSubClasseRocha;

      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idSubClasseRocha",
        idSubClasseRocha
      );

      formRef.initialValues.informacoesAmostra.idPrefixoRocha = idPrefixoRocha;
      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idPrefixoRocha",
        idPrefixoRocha
      );

      formRef.initialValues.informacoesAmostra.idComplementoRocha =
        idComplementoRocha;

      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
          "idComplementoRocha",
        idComplementoRocha
      );
      formRef.initialValues.informacoesAmostra.idRocha = idRochaNomeada;

      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idRocha",
        idRochaNomeada
      );

      if (!dataUltimaAtualizacaoVinculo) return;

      const date = dayjs(dataUltimaAtualizacaoVinculo).format("DD/MM/YYYY");

      formRef.initialValues.informacoesAmostra.dataUltimaAtualizacaoVinculo =
        date;

      formRef.form.mutators.setValue(
        prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
          "dataUltimaAtualizacaoInformacaoOrigem",
        date
      );
    },

    [formRef.form.mutators, formRef.initialValues]
  );

  const montarValoresRochaAfloramento = useCallback(() => {
    if (
      !rochaAfloramento ||
      !Object.keys(rochaAfloramento).length ||
      !Object.keys(amostraSelecionada).length
    )
      return;

    montarValores(
      rochaAfloramento?.idTipoRocha,
      rochaAfloramento?.idSubClasseRocha,
      rochaAfloramento?.idPrefixo,
      rochaAfloramento?.idComplemento,
      rochaAfloramento?.idRochaNomeada,
      associacao?.dataUltimaAtualizacao
    );
  }, [
    amostraSelecionada,
    associacao?.dataUltimaAtualizacao,
    montarValores,
    rochaAfloramento,
  ]);

  const montarValoresRochaPetro = useCallback(() => {
    if (
      !rochaPetrografia ||
      !Object.keys(rochaPetrografia).length ||
      !Object.keys(amostraSelecionada).length
    )
      return;

    montarValores(
      rochaPetrografia?.idClasseRocha,
      rochaPetrografia?.idSubClasseRocha,
      rochaPetrografia?.idPrefixo,
      rochaPetrografia?.idComplemento,
      rochaPetrografia?.idRocha,
      rochaPetrografia?.dataUltimaAtualizacao
    );
  }, [rochaPetrografia, amostraSelecionada, montarValores]);

  useEffect(() => {
    if (
      rochaAfloramento &&
      Object.keys(rochaAfloramento).length &&
      !estaEditando
    )
      montarValoresRochaAfloramento();

    if (
      rochaPetrografia &&
      Object.keys(rochaPetrografia).length &&
      !estaEditando
    )
      montarValoresRochaPetro();
  }, [
    estaEditando,
    montarValoresRochaAfloramento,
    montarValoresRochaPetro,
    rochaAfloramento,
    rochaPetrografia,
  ]);

  const getIdTipoRocha = useCallback(async (idMaterialGeologico) => {
    return consultarRochasAfloramento(idMaterialGeologico);
  }, []);

  const tratarFichasPetrograficas = (
    fichas: {
      dataUltimaAtualizacao?: string;
      classificacaoInterpretacaoPetrograficaSedimentar: any;
      classificacaoInterpretacaoPetrograficaIgneaMetamorfica: any;
      fichaPetrografica: string;
      composicoesMineralogicas: [];
      id: number;
    }[]
  ) => {
    if (!fichas.length) return null;

    const todosSemData = fichas.every((item) => !item.dataUltimaAtualizacao);

    if (todosSemData) return fichas?.[fichas.length - 1] || null;

    const fichasComData = fichas.filter((item) => item.dataUltimaAtualizacao);

    const fichasOrdenadas = fichasComData.sort((a, b) => {
      return (
        new Date(a.dataUltimaAtualizacao!).getTime() -
        new Date(b.dataUltimaAtualizacao!).getTime()
      );
    });

    return fichasOrdenadas?.[0];
  };
  const pegarInformacaoDoVinculo = useCallback(async () => {
    const response = await recuperarInformacoesDoVinculo(
      formRef?.form?.getState()?.values?.id
    );
    setInfoVinculo(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const criarOrigemDaInformacaoLabel = useCallback(() => {
    const nomeAssociacao =
      formRef?.form?.getState()?.values?.informacoesAmostra?.nomeAssociacao ||
      tipoAssociação;

    let label: string | null = "";

    if (
      nomeAssociacao === TIPOS_ASSOCIAÇÃO_ENUM.NENHUM ||
      amostraDeBibliografia
    )
      label = "";
    else if (infoVinculo) label = nomeAssociacao + " - Excluído";
    else label = nomeAssociacao;
    return setLabelEdicao(label);
  }, [amostraDeBibliografia, formRef?.form, infoVinculo, tipoAssociação]);

  useEffect(() => {
    if (
      infoVinculo !== null &&
      tipoAssociação !== null &&
      formRef?.form?.getState()?.values?.informacoesAmostra?.nomeAssociacao !==
        null &&
      estaEditando
    )
      criarOrigemDaInformacaoLabel();
  }, [
    criarOrigemDaInformacaoLabel,
    estaEditando,
    formRef?.form,
    infoVinculo,
    tipoAssociação,
  ]);

  const pegarInformaçõesSobreOVinculoDaAmostra = useCallback(async () => {
    if (estaEditando) await pegarInformacaoDoVinculo();
    const associacaoAfloramento = amostraSelecionada?.associacoes?.find(
      (x) => x.tipoAtividade === "AFLORAMENTO"
    );

    const associacaoPetografia = amostraSelecionada?.associacoes?.find(
      (x) => x.tipoAtividade === "PETROGRAFIA"
    );

    let petrografiaOrigemComValor: boolean = true;
    let afloramentoOrigemComValor: boolean = true;

    if (!estaEditando) {
      const visitasResponse = await consultarVisitasPorIdEstacao(
        amostraSelecionada.idEstacao
      );
      setVisita(
        visitasResponse.find(
          (visita) => +visita.id === amostraSelecionada.idVisita
        )
      );
    }

    if (associacaoPetografia) {
      setTipoAssociação(TIPOS_ASSOCIAÇÃO_ENUM.PETROGRAFIA);

      setAssociacao(associacaoPetografia);

      const rochasPetrografia = await consultarRochaPetrografia(
        amostraSelecionada.id
      );
      if (!rochasPetrografia.length) petrografiaOrigemComValor = false;

      const fichaMaisRecenteParaUsarDeBase = tratarFichasPetrograficas(
        rochasPetrografia || []
      );

      if (fichaMaisRecenteParaUsarDeBase) {
        const dadosRochaFicha =
          fichaMaisRecenteParaUsarDeBase.fichaPetrografica ===
          IGNEAS_METAMORFICAS
            ? fichaMaisRecenteParaUsarDeBase.classificacaoInterpretacaoPetrograficaIgneaMetamorfica
            : fichaMaisRecenteParaUsarDeBase.classificacaoInterpretacaoPetrograficaSedimentar;

        Object.assign(dadosRochaFicha, {
          composicoesMineralogicas:
            fichaMaisRecenteParaUsarDeBase.composicoesMineralogicas,
          dataUltimaAtualizacao:
            fichaMaisRecenteParaUsarDeBase.dataUltimaAtualizacao,
        });
        setIdAssociacao(fichaMaisRecenteParaUsarDeBase?.id);
        setRochaPetrografia(dadosRochaFicha);
      }
    }
    if (
      (!associacaoPetografia && associacaoAfloramento) ||
      (associacaoPetografia &&
        (petrografiaOrigemComValor as boolean) === false &&
        associacaoAfloramento !== undefined)
    ) {
      setTipoAssociação(TIPOS_ASSOCIAÇÃO_ENUM.AFLORAMENTO);
      setAssociacao(associacaoAfloramento);

      const idMaterialGeologico = associacaoAfloramento.idMaterialGeologico;

      const rochaAfloramento = await getIdTipoRocha(idMaterialGeologico);
      if (!rochaAfloramento || !Object?.keys(rochaAfloramento)?.length)
        afloramentoOrigemComValor = false;

      setIdAssociacao(associacaoAfloramento?.idAtividade);
      setRochaAfloramento(rochaAfloramento);
    }

    if (
      (!associacaoAfloramento && !associacaoPetografia) ||
      (!afloramentoOrigemComValor && !petrografiaOrigemComValor) ||
      (!associacaoAfloramento && !petrografiaOrigemComValor) ||
      (!associacaoPetografia && !afloramentoOrigemComValor)
    ) {
      setTipoAssociação(TIPOS_ASSOCIAÇÃO_ENUM.NENHUM);
    }

    setMostrarLabel(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    estaEditando,
    pegarInformacaoDoVinculo,
    amostraSelecionada?.associacoes,
    amostraSelecionada.idEstacao,
    amostraSelecionada.idVisita,
    amostraSelecionada.id,
    getIdTipoRocha,
  ]);

  useEffect(() => {
    if (
      amostraDeBibliografia === null ||
      (amostraSelecionada &&
        Object.keys(amostraSelecionada).length &&
        amostraDeBibliografia === true)
    )
      return;

    pegarInformaçõesSobreOVinculoDaAmostra();
  }, [
    amostraDeBibliografia,
    amostraSelecionada,
    pegarInformaçõesSobreOVinculoDaAmostra,
  ]);

  useEffect(() => {
    if (!amostraSelecionada) return;

    setAmostraDeBibliografia(
      amostraSelecionada.statusBibliografia === STATUSES_BIBLIOGRAFIA_ENUM.S
    );

    return () => {
      setAmostraDeBibliografia(false);
    };
  }, [amostraSelecionada]);

  useEffect(() => {
    if (!amostraSelecionada || !Object.keys(amostraSelecionada).length) {
      setDadosCarregados(true);
      return;
    }
    (async () => {
      const [
        classesRochaResponse,
        subClassesRochaResponse,
        prefixosRochaResponse,
        rochasResponse,
        complementosRochaResponse,
        unidadesLitoEstratigraficaResponse,
      ] = await Promise.all([
        consultarClassesRocha(),
        consultarSubClassesRocha(),
        consultarPrefixosRocha(),
        consultarRochas(),
        consultarComplementosRocha(),
        consultarUnidadeLitoEstratigraficas(amostraSelecionada),
      ]);

      setClassesRocha(classesRochaResponse);
      setSubClassesRochaCompleto(subClassesRochaResponse);
      setPrefixosRocha(prefixosRochaResponse);
      setRochasCompleto(rochasResponse);
      setComplementosRocha(complementosRochaResponse);
      setUnidadesLitoEstratigrafica(unidadesLitoEstratigraficaResponse);

      setDadosCarregados(true);
    })();
  }, [amostraSelecionada]);

  const limparInputELimparValorNoStateDoForm = (
    inputName: string,
    formAttributeName: string
  ) => {
    forcarLimparComboBox(inputName);
    formRef.form.mutators.setValue(formAttributeName, null);
  };

  const construirSubClassesRochaValores = useCallback(
    (idClasseRocha: number) => {
      let filtro1 = rochasCompleto.filter(
        (r) => r.idTipoRocha === idClasseRocha && r.rochaAtual === "S"
      );
      let filtro2 = [...new Set(filtro1.map((i) => i.idSubclasseRocha))];

      let listaFiltrada = subClassesRochaCompleto.filter((subClasse) =>
        filtro2.find((i) => i === subClasse.id)
      );

      setSubClassesRocha(listaFiltrada);
    },
    [rochasCompleto, subClassesRochaCompleto]
  );

  const construirRochasValores = useCallback(
    (idSubClasseRocha: number) => {
      setRochas(
        rochasCompleto?.filter(
          (rocha) =>
            rocha.idTipoRocha === idClasseRochaSelecionada &&
            rocha.idSubclasseRocha === idSubClasseRocha &&
            (rocha?.rochaAtual === "S" || rocha.id === idRochaSelecionada)
        )
      );
    },
    [rochasCompleto, idClasseRochaSelecionada, idRochaSelecionada]
  );

  useEffect(() => {
    if (
      (estaEditando || estaVisualizando) &&
      formRef?.form?.getState?.()?.values
    ) {
      const idClasseRocha =
        formRef.form.getState().values.informacoesAmostra.idClasseRocha;
      const idSubClasseRocha =
        formRef.form.getState().values.informacoesAmostra.idSubClasseRocha;

      setIdClasseRochaSelecionada(idClasseRocha);
      setIdSubClasseRochaSelecionada(idSubClasseRocha);

      construirSubClassesRochaValores(idClasseRocha);

      construirRochasValores(idSubClasseRocha);
    }
  }, [
    construirRochasValores,
    construirSubClassesRochaValores,
    estaEditando,
    estaVisualizando,
    formRef.form,
    idClasseRochaSelecionada,
    rochasCompleto,
    subClassesRochaCompleto,
  ]);

  const aoSelecionarClasseRocha = (idClasseRocha: number) => {
    limparInputELimparValorNoStateDoForm(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idSubClasseRocha",
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idSubClasseRocha"
    );
    limparInputELimparValorNoStateDoForm(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idRocha",
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idRocha"
    );

    construirSubClassesRochaValores(idClasseRocha);

    setIdClasseRochaSelecionada(idClasseRocha);
    setIdSubClasseRochaSelecionada(null);
  };

  const aoSelecionarSubClasseRocha = (idSubClasseRocha: number) => {
    limparInputELimparValorNoStateDoForm(
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idRocha",
      prefixoDoFormularioParaAcoplarOsAtributosNumObjeto + "idRocha"
    );
    setIdSubClasseRochaSelecionada(idSubClasseRocha);

    construirRochasValores(idSubClasseRocha);
  };

  const aoAdicionarRocha = (idRocha: number) => {
    setIdRochaSelecionada(idRocha);
  };

  const validarCamposBibliografia = useCallback(
    (
      valor: string | number,
      nomeCampo: string = "Este campo é obrigatório!"
    ) => {
      if (!amostraDeBibliografia) return;

      if (!valor)
        return `Um valor deve ser informado para o campo:  ${nomeCampo}`;
    },
    [amostraDeBibliografia]
  );

  const validarCamposNaoBibliografia = useCallback(
    (valor: string | number, nomeCampo: string = "") => {
      if (amostraDeBibliografia) return;
      if (!valor)
        return `Um valor deve ser informado para o campo:  ${nomeCampo}`;
    },
    [amostraDeBibliografia]
  );

  const recuperaDisabled = useCallback(() => {
    if (disabled) return true;
    if (amostraDeBibliografia) return false;
    if (!estaEditando && tipoAssociação === TIPOS_ASSOCIAÇÃO_ENUM.NENHUM)
      return false;
    if (
      estaEditando &&
      formRef?.form?.getState()?.values?.informacoesAmostra?.nomeAssociacao ===
        TIPOS_ASSOCIAÇÃO_ENUM.NENHUM
    )
      return false;

    return true;
  }, [
    amostraDeBibliografia,
    disabled,
    estaEditando,
    formRef?.form,
    tipoAssociação,
  ]);

  useEffect(() => {
    if (
      disabled !== null ||
      tipoAssociação !== null ||
      amostraDeBibliografia !== null
    )
      setDesabilitado(recuperaDisabled());
  }, [disabled, tipoAssociação, amostraDeBibliografia, recuperaDisabled]);

  return (
    <>
      {desabilitado !== null && (
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>Informações da amostra</Card.Title>

            <BlockUi blocking={!dadosCarregados}>
              <CamposDaParteSuperior
                amostraDeBibliografia={amostraDeBibliografia}
                validarCamposBibliografia={validarCamposBibliografia}
                validarCamposNaoBibliografia={validarCamposNaoBibliografia}
                prefixo={prefixoDoFormularioParaAcoplarOsAtributosNumObjeto}
                unidadesLitoEstratigrafica={unidadesLitoEstratigrafica}
                disabled={disabled}
                coletores={coletores}
                isChefeProjeto={isChefeProjeto}
                idUsuarioAtual={idUsuarioAtual}
              />
              <Field
                name={
                  prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                  "nomeAssociacao"
                }
                component={HiddenField}
                disabled={true}
              />
              <Field
                name={
                  prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                  "idAssociacao"
                }
                disabled={true}
                component={HiddenField}
              />

              <Card className="mt-3">
                <Card.Body>
                  <Card.Title>Rocha</Card.Title>
                  <Row>
                    <Col md={4}>
                      <Field
                        component={DropdownListField}
                        elementos={classesRocha}
                        disabled={desabilitado}
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idClasseRocha"
                        }
                        label="Classe da Rocha"
                        required={!amostraDeBibliografia}
                        dica="Classe da Rocha"
                        customOnChange={aoSelecionarClasseRocha}
                        validate={(valor) =>
                          validarCamposNaoBibliografia(valor, "Classe da Rocha")
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        component={DropdownListField}
                        elementos={
                          amostraDeBibliografia ||
                          tipoAssociação === TIPOS_ASSOCIAÇÃO_ENUM.NENHUM
                            ? subClassesRocha
                            : subClassesRochaCompleto
                        }
                        disabled={
                          !desabilitado ? !idClasseRochaSelecionada : true
                        }
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idSubClasseRocha"
                        }
                        label="Subclasse da Rocha"
                        dica="Subclasse da Rocha"
                        customClass={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idSubClasseRocha"
                        }
                        required={!amostraDeBibliografia}
                        customOnChange={aoSelecionarSubClasseRocha}
                        validate={(valor) =>
                          validarCamposNaoBibliografia(
                            valor,
                            "Subclasse da Rocha"
                          )
                        }
                      />
                    </Col>

                    <Col md={4}>
                      {!estaEditando &&
                        mostrarLabel &&
                        tipoAssociação !== TIPOS_ASSOCIAÇÃO_ENUM.NENHUM &&
                        tipoAssociação && (
                          <div className="label-origem-info">
                            Origem da informação:
                            {tipoAssociação}
                          </div>
                        )}
                      {estaEditando && (
                        <div className="label-origem-info">
                          {labelEdicao
                            ? "Origem da informação: " + labelEdicao
                            : ""}
                        </div>
                      )}
                      <Field
                        label="Data da última atualização da informação"
                        dica="Data da última atualização da informação"
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "dataUltimaAtualizacaoInformacaoOrigem"
                        }
                        disabled={true}
                        component={DateTimeInputField}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Field
                        component={DropdownListField}
                        elementos={prefixosRocha}
                        disabled={desabilitado}
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idPrefixoRocha"
                        }
                        required={false}
                        label="Prefixo"
                        dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo"
                      />
                    </Col>

                    <Col md={4}>
                      <Field
                        component={DropdownListField}
                        elementos={
                          amostraDeBibliografia ||
                          tipoAssociação === TIPOS_ASSOCIAÇÃO_ENUM.NENHUM
                            ? rochas
                            : rochasCompleto
                        }
                        disabled={
                          !desabilitado
                            ? !idSubClasseRochaSelecionada ||
                              !idClasseRochaSelecionada
                            : true
                        }
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idRocha"
                        }
                        required={!amostraDeBibliografia}
                        label="Rocha"
                        customClass={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idRocha"
                        }
                        validate={(valor) =>
                          validarCamposNaoBibliografia(valor, "Rocha")
                        }
                        customOnChange={aoAdicionarRocha}
                        dica="Selecione o nome simples da rocha"
                      />
                    </Col>

                    <Col md={4}>
                      <Field
                        component={DropdownListField}
                        elementos={complementosRocha}
                        disabled={desabilitado}
                        name={
                          prefixoDoFormularioParaAcoplarOsAtributosNumObjeto +
                          "idComplementoRocha"
                        }
                        label="Complemento"
                        required={false}
                        dica="Se for o caso, defina o complemento de nomenclatura de sua rocha de acordo com o tipo"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        let composicoesMineralogicas: {
                          compoeNomeRocha: string;
                          id: number;
                          idMineral: number;
                        }[] = [];
                        if (
                          rochaPetrografia &&
                          tipoAssociação ===
                            TIPOS_ASSOCIAÇÃO_ENUM.PETROGRAFIA &&
                          rochaPetrografia.composicoesMineralogicas
                        ) {
                          composicoesMineralogicas = resolverExibidos(
                            rochaPetrografia.composicoesMineralogicas
                          );
                        }

                        if (
                          rochaAfloramento &&
                          tipoAssociação ===
                            TIPOS_ASSOCIAÇÃO_ENUM.AFLORAMENTO &&
                          rochaAfloramento.mineraisNomenclatura
                        ) {
                          composicoesMineralogicas = resolverExibidos(
                            rochaAfloramento.mineraisNomenclatura
                          );
                        }

                        const composicoesMineralogicasQueCompoem =
                          composicoesMineralogicas.filter(
                            (mineral) =>
                              mineral.compoeNomeRocha === "S" ||
                              mineral.compoeNomeRocha === "SIM"
                          );

                        const nomesMinerais = bbMinerais
                          .filter((mineral) =>
                            composicoesMineralogicasQueCompoem.some(
                              (min) => min.idMineral === mineral.id
                            )
                          )
                          .map((mineral) => mineral.nome);

                        const nomesMineraisString = nomesMinerais.join(" ");
                        const nomesMineraisParte = nomesMineraisString
                          ? nomesMineraisString + " "
                          : "";

                        const prefixoRocha: any = prefixosRocha.find(
                          (v: { id: number }) =>
                            v.id ===
                            values?.[
                              prefixoFormularioParaRecuperarOsValoresDoObjeto
                            ]?.idPrefixoRocha
                        );
                        const rocha: any = rochasCompleto.find(
                          (rocha) =>
                            rocha.id ===
                            values?.[
                              prefixoFormularioParaRecuperarOsValoresDoObjeto
                            ]?.idRocha
                        );
                        const complemento: any = complementosRocha.find(
                          (v: { id: number }) =>
                            v.id ===
                            values?.[
                              prefixoFormularioParaRecuperarOsValoresDoObjeto
                            ]?.idComplementoRocha
                        );

                        const prefixoParte = prefixoRocha?.nome
                          ? prefixoRocha.nome + " "
                          : "";
                        const rochaParte = rocha?.nome ? rocha.nome + " " : "";
                        const complementoParte = complemento?.nome
                          ? complemento.nome + " "
                          : "";
                        const valor =
                          nomesMineraisParte +
                          prefixoParte +
                          rochaParte +
                          complementoParte;
                        return (
                          <Col md={12}>
                            <div style={{ color: "blue", fontSize: 12 }}>
                              {valor}
                            </div>
                          </Col>
                        );
                      }}
                    </FormSpy>
                  </Row>
                </Card.Body>
              </Card>
              <CamposDaParteInferior
                prefixo={prefixoDoFormularioParaAcoplarOsAtributosNumObjeto}
                toponimiaDisabled={amostraDeBibliografia ? false : true}
                disabled={disabled}
              />
            </BlockUi>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
