

const mapeamentoTipoArquivo = {
    "word": ["doc", "docx", "odt"],
    "excel": ["xlsx", "csv", "xls"],
    "imagem": [(v) => v.startsWith("image/"), "png", "jpg", "jpeg", "svg", "tif", "tiff", "bmp"],
    "pdf": [(v) => v.includes("pdf")],
    "audio": ["mp3", "mpeg", "wma", "x-ms-wma", "wav", "ogg", "alac", "flac"],
    "video": ["3gp", "3gpp", "flv", "x-flv", "avi", "x-msvideo", "rmvb", "mov", "quicktime", "mp4", "mkv", "x-matroska", "m4v", "x-m4v", "wmv", "x-ms-wmv"],
    "zip": [v => v.includes("zip"), v => v.includes("rar")]
};

const mapearTipo = (tipo) => {
    try {
        tipo = tipo.toLowerCase();
        return Object.entries(mapeamentoTipoArquivo).reduce((res, [key, value]) => {
            if (res) return res;
            if (key === tipo) return key;
            for (const alias of value) {
                if (alias === tipo) return key;
                if (typeof alias === "function" && alias(tipo)) return key;
            }
            return null;
        }, null) || "file";
    }
    catch (err) {
        console.log('\n ERRO AO MAPEAR TIPO', err);
        return null;
    }
};

export { mapearTipo };