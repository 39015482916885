import { FormApi } from "final-form";
import { memo, useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import DropdownListFieldWithCheckbox from "../../../../../../components/field/DropdownListField/DropdownListFieldWithCheckbox";
import NumberInputField from "../../../../../../components/field/NumberInputField/NumberInputField";
import { SubForm } from "../../../../../../components/SubForm";
import { aoEnviarSubForm } from "../../../../../../util/mutadores";
import { resolverExibidos } from "../../../../../../components/SubForm/SubForm";
import { consultarAmostrasProximasPorIdAmostra } from "../../services/subFormDetalhesIsocronasService";
import BlockUi from "react-block-ui";
import { AmostrasProximas } from "../../types/amostra";
import { campoObrigatorioComMsgGenerica } from "../../../../../../util/validadores";

type AmostraFormatada = { id: number; nome: string };

const SubFormDetalhesIsocronas = ({
  formRef,
  disabled = false,
  permitirInsercao,
  idAmostra,
}: {
  formRef: { form: FormApi; initialValues: any };
  disabled?: boolean;
  permitirInsercao: boolean;
  idAmostra: number;
}) => {
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [editando, setEditando] = useState<boolean>(false);
  const [visualizando, setVisualizando] = useState<boolean>(false);

  const [editandoValores, setEditandoValores] = useState<string>();
  const [listaAmostras, setListaAmostras] = useState<AmostraFormatada[]>();

  const onSubmit = (dadosRecebidos) => {

    const estaExcluindo = dadosRecebidos?.dadosAdicionais?.elementoExcluido

    if (!editando && !estaExcluindo) {
      const novosElementos = dadosRecebidos.novosElementos.novos;
      const ultimoElementoRemovido = novosElementos.pop();
      const idsAmostras = ultimoElementoRemovido.idsAmostrasIsocronas;

      let maiorIndice = -1;
      for (const key in dadosRecebidos.alteracoesForm) {
        if (key.startsWith("detalheIsocrona.novos[")) {
          const match = key.match(/\[(\d+)\]/);
          if (match) {
            const indice = parseInt(match[1]);
            if (indice > maiorIndice) {
              maiorIndice = indice;
            }
          }
        }
      }

      //Limpa alteracoesForm antiga qeu tem mais de um registro de idsAmostrasIsocronas
      const { alteracoesForm } = dadosRecebidos;
      delete alteracoesForm[`detalheIsocrona.novos[${maiorIndice}].id`];
      for (let i = 1; i <= idsAmostras.length; i++) {
        delete alteracoesForm[`detalheIsocrona.novos[${maiorIndice}].idsAmostrasIsocronas[${i -1}]`];
      }
      delete alteracoesForm[`detalheIsocrona.novos[${maiorIndice}].epsilon`];
      delete alteracoesForm[`detalheIsocrona.novos[${maiorIndice}].razaoInicial`];

      //Cria registro antigo com somente primeiro idsAmostrasIsocronas
      const novoDados = {
        ...dadosRecebidos,
        alteracoesForm: {
          ...dadosRecebidos.alteracoesForm,
          [`detalheIsocrona.novos[${maiorIndice}].id`]: ultimoElementoRemovido.id,
          [`detalheIsocrona.novos[${maiorIndice}].idsAmostrasIsocronas[0]`]: idsAmostras[0],
          [`detalheIsocrona.novos[${maiorIndice}].epsilon`]: ultimoElementoRemovido.epsilon,
          [`detalheIsocrona.novos[${maiorIndice}].razaoInicial`]: ultimoElementoRemovido.razaoInicial,
        },
      };

      // Limita valor do id negativo para não causar erro no backend
      const valorBase = 2147483647;

      //Cria novos registros para outos itens de idsAmostrasIsocronas
      if (idsAmostras.length > 1) {
        for (let i = 1; i < idsAmostras.length; i++) {
          novoDados.alteracoesForm[`detalheIsocrona.novos[${maiorIndice + i}].id`] = -(Math.abs(Date.now() + i) % valorBase);
          novoDados.alteracoesForm[`detalheIsocrona.novos[${maiorIndice + i}].idsAmostrasIsocronas[0]`] = idsAmostras[i];
          novoDados.alteracoesForm[`detalheIsocrona.novos[${maiorIndice + i}].epsilon`] = ultimoElementoRemovido.epsilon;
          novoDados.alteracoesForm[`detalheIsocrona.novos[${maiorIndice + i}].razaoInicial`] = ultimoElementoRemovido.razaoInicial;
        }
      }
      const onSubmitOriginal = aoEnviarSubForm(formRef.form);
      onSubmitOriginal(novoDados);
    } else {
      const onSubmitOriginal = aoEnviarSubForm(formRef.form);
        onSubmitOriginal(dadosRecebidos);
    }
  };

  const consultarAmostrasProximasPorAmostraId = useCallback(
    async (idAmostra: number) => {
      setCarregandoDados(true);
      const response: AmostrasProximas[] =
        await consultarAmostrasProximasPorIdAmostra(idAmostra);
      setListaAmostras(
        response?.map((amostra) => ({
          id: amostra.idAmostra,
          nome:
            amostra.numeroAmostra +
            (amostra.stBibliografia === "S"
              ? "/Bibliografia"
              : "/Não-Bibliografia"),
        }))
      );
      setCarregandoDados(false);
    },
    []
  );

  useEffect(() => {
    (async () => await consultarAmostrasProximasPorAmostraId(idAmostra))();
    return () => {};
  }, [consultarAmostrasProximasPorAmostraId, idAmostra]);

  const colunas = [
    {
      text: "Demais amostras da integração",
      datafield: `idsAmostrasIsocronas`,
      width: "60%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const arrayDeIds: number[] = value.includes(",")
          ? value.split(",").map(Number)
          : [+value];

        const valor = listaAmostras
          ?.filter((amostra) => arrayDeIds.includes(amostra.id))
          .map((amostra) => amostra.nome)
          .join(", ");
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
      },
    },
    {
      text: "Épsilon",
      datafield: `epsilon`,
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const formattedValue = value.toString().replace('.', ',');
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${formattedValue}</div>`;
      },
    },
    {
      text: "Razão inicial",
      datafield: `razaoInicial`,
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const formattedValue = value.toString().replace('.', ',');
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${formattedValue}</div>`;
      },
    },
  ];
  const campos = [
    {
      name: `idsAmostrasIsocronas`,
      type: "array",
      map: `idsAmostrasIsocronas`,
    },
    { name: `epsilon`, type: "number", map: `epsilon` },
    {
      name: `razaoInicial`,
      type: "number",
      map: `razaoInicial`,
    },
  ];

  return (
    <BlockUi blocking={carregandoDados}>
      <div style={{ marginTop: "2.5rem" }}>
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>Detalhes da(s) isócrona(s)</Card.Title>
            <Field name={`detalheIsocrona`} subscription={{ value: true }}>
              {({ input: { value: detalheIsocrona = [] } }) => (
                <>
                  <SubForm
                    nome={`detalheIsocrona`}
                    onSubmit={onSubmit}
                    modoSubFormComGrid={true}
                    exibirTabelaSemRegistro={false}
                    colunas={colunas}
                    elementos={detalheIsocrona}
                    campos={campos}
                    podeExcluirEntidade={() => !disabled}
                    permitirInsercao={permitirInsercao}
                    exibirBotaoInsercao={permitirInsercao}
                    permitirEdicao={!disabled}
                    permitirExclusao={!disabled}
                    permitirVisualizacao={disabled}
                    onBtnViewClick={() => {
                      setVisualizando(true);
                      setEditando(false);
                    }}
                    onBtnEditClick={(row) => {
                      setEditando(true);
                      setVisualizando(false);
                      setEditandoValores(
                        row.rowdata.rowData.idsAmostrasIsocronas
                      );
                    }}
                    onBtnVoltar={() => {
                      setEditando(false);
                      setVisualizando(false);
                    }}
                    onBtnAdicionar={() => {
                      setEditando(false);
                      setVisualizando(false);
                    }}
                    renderForm={({ formProps }) => {
                      const valoresSelecionados: {
                        idsAmostrasIsocronas: number[];
                      }[] = formRef.form?.getState()?.values?.detalheIsocrona
                        ? resolverExibidos(
                            formRef.form.getState().values.detalheIsocrona
                          )
                        : [];

                      const todasAslinhasSalvas = new Set<number>();

                      valoresSelecionados.forEach((linha) =>
                        linha.idsAmostrasIsocronas.forEach((id) =>
                          todasAslinhasSalvas.add(id)
                        )
                      );

                      let options: AmostraFormatada[] = [];
                      if (visualizando) {
                        options = listaAmostras ?? [];
                      } else if (editando) {
                        const arrayComValores = editandoValores
                          ?.split(",")
                          .map((id) => +id);

                        const idsSet = new Set(arrayComValores);

                        let opcoesNaoEscolhidasDeOutrasLinhas =
                          listaAmostras?.filter(
                            (opc) =>
                              !todasAslinhasSalvas.has(opc.id) &&
                              !idsSet.has(opc.id)
                          ) || [];

                        let valoresDessaLinhaSendoEditada =
                          listaAmostras?.filter((opc) => idsSet.has(opc.id)) ||
                          [];

                        options = [
                          ...valoresDessaLinhaSendoEditada,
                          ...opcoesNaoEscolhidasDeOutrasLinhas,
                        ];
                      } else {
                        options =
                          listaAmostras?.filter(
                            (opc) => !todasAslinhasSalvas.has(opc.id)
                          ) || [];
                      }
                      return (
                        <Row className="my-3">
                          <Col md={4}>
                            <Field
                              component={DropdownListFieldWithCheckbox}
                              elementos={options}
                              name={`idsAmostrasIsocronas`}
                              selectAllLabel= "Selecione todas as amostras"
                              label="Demais amostras da integração"
                              dica="Se for o caso, selecione as demais amostras cujos dados foram utilizados no cálculo da isócrona."
                              required
                              validate={campoObrigatorioComMsgGenerica(
                                "Demais amostras da integração"
                              )}
                              disabled={disabled || editando}
                            />
                          </Col>
                          <Col md={3}>
                            <Field
                              component={NumberInputField}
                              casasInteiros={5}
                              casasDecimais={2}
                              name={`epsilon`}
                              label="Épsilon"
                              dica="Insira o valor de épsilon da isócrona"
                              disabled={disabled}
                            />
                          </Col>

                          <Col md={3}>
                            <Field
                              component={NumberInputField}
                              casasInteiros={5}
                              casasDecimais={5}
                              name={`razaoInicial`}
                              label="Razão inicial"
                              dica="Insira o valor da razão inicial do isótopo filho, obtido na regressão da isócrona."
                              disabled={disabled}
                            />
                          </Col>
                        </Row>
                      );
                    }}
                  />
                </>
              )}
            </Field>
          </Card.Body>
        </Card>
      </div>
    </BlockUi>
  );
};

export default memo(SubFormDetalhesIsocronas, () => true);
