import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { Field, useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { SubForm } from "../../../../components/SubForm";
import { mostrarNotificacao } from "../../../../reducers/notificacaoReducer";
import Service, {
  BASEGEO,
  GEOCRON,
  VALIDACAO,
} from "../../../../service/Service";
import { createValidationColumn } from "../../../validacao/utils/validationGridUtils";
import SubFormDetalhesAquecimento from "./components/detalhesEquipamento/SubFormDetalhesAquecimento";
import { SubFormAmostra } from "./components/informacoesAmostra/SubFormAmostra";
import {
  consultarGeocronologiasPorIdAmostra,
  deletarGeocronologiaPorId,
  salvarGeocronologia,
} from "./services/geocrologiaServices";
import { Geocronologia, GeocronologiasProps } from "./types/geocronologiaTypes";
import SubFormDetalhesIsocronas from "./components/detalhesIsocrononas/SubFormDetalhesIsocronas";
import MaterialSuplementar from "../../sharedComponents/MaterialSuplementar";
import dayjs from "dayjs";
import React from "react";
import swal from "sweetalert";
import { HiddenField } from "../../../../components/field/HiddenField";
import gerarPdfRedirecionarNovaAba from "../../../estacoes/utils/gerarPdfRedirecionarNovaAba";
import FocusableButton from "../../../../components/Jqx/Button/FocusableButton";
import SubFormIdadeAssociadaAmostra from "./components/IdadeAssociadaAmostra/SubFormIdadeAssociadaAmostra";

const Geocronologias = ({
  permitirInsercao,
  permitirVisualizacao,
  permitirEdicao,
  permitirGerarPdf,
  onMount,
  idAmostra,
  amostraSelecionada,
  idUsuarioAtual,
  isChefeProjeto,
  permitirExclusao,
  amostraOriginal,
  permitirEnviarParaRevisao,
  idProjetoCorrente,
  idProjetoHistorico,
  idArea,
  idSubArea,
  coletores
}: GeocronologiasProps) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [valoresIniciaisEdicao, setValoresIniciaisEdicao] = useState({});
  const [carregamentoGlobal, setCarregamentoGlobal] = useState(true);
  const [geocronologias, setGeoCronologias] = useState<Geocronologia[]>([]);
  const [mostrarBotaoRevisao, setMostrarBotaoRevisao] = useState(true);
  const [podeInserir, setPodeInserir] = useState(permitirInsercao);
  const [podeEditar, setPodeEditar] = useState(permitirEdicao);

  const [podeExcluir, setPodeExcluir] = useState(permitirExclusao);
  const [bbSistemasIsotopicos, setBbSistemaIsotopico] = useState<any>([]);
  const [bbTipoIdade, setBbTipoIdade] = useState<any>([]);
  const [bbMaterialAnalisado, setBbMaterialAnalisado] = useState<any>([]);
  const [bbTecnicaAnalitica, setBbTecnicaAnalitica] = useState<any>([]);
  const [bbSigma, setBbSigma] = useState<any>([]);
  const [bbInterpretacao, setBbInterpretacao] = useState<any>([]);
  const [bbExtencaoIlustracao, setBbExtencaoIlustracao] = useState<any>([]);
  const [bbTipoIlustracao, setBbTipoIlustracao] = useState<any>([]);
  const [bbMinerais, setBbMinerais] = useState<{ id: number; nome: string }[]>(
    []
  );
  const [estaEditando, setEstaEditando] = useState(false);
  const [estaVisualizando, setEstaVisualizando] = useState(false);
  const [rowsSelecionadas, setRowsSelecionadas] = useState<
    { checkboxMultiplaSelecao: boolean; id: number }[]
  >([]);

  const tabelaRef = useRef();

  useEffect(() => {
    setPodeExcluir(permitirExclusao);
    return () => {};
  }, [permitirExclusao]);

  useEffect(() => {
    setPodeEditar(permitirEdicao);
    return () => {};
  }, [permitirEdicao]);

  useEffect(() => {
    setPodeInserir(permitirInsercao);
    return () => {};
  }, [permitirInsercao]);

  useEffect(() => {
    setDisabled(false);
    return () => {};
  }, [permitirVisualizacao]);

  async function compatibilizarCampos() {
    try {
      const [
        bbSistemasIsotopicos,
        bbTecnicaAnalitica,
        bbTipoIdade,
        bbMaterialAnalisado,
        bbSigma,
        bbInterpretacao,
        tiposIlustracao,
        extencoesIlustracao,
        bbMinerais,
      ] = await Promise.all([
        Service(`/sistemas-isotopicos`, GEOCRON).query(),
        Service(`/tecnica-analitica`, GEOCRON).query(),
        Service(`/tipo-idade`, GEOCRON).query(),
        Service(`/material-analisado`, GEOCRON).query(),
        Service(`/sigma`, GEOCRON).query(),
        Service(`/interpretacao-autor`, GEOCRON).query(),
        Service("/tiposIlustracao").query(),
        Service("/extencoesIlustracao").query(),
        Service("/minerais", BASEGEO).query(),
      ]);
      setBbSistemaIsotopico(bbSistemasIsotopicos?.data?.dados);
      setBbTecnicaAnalitica(bbTecnicaAnalitica?.data?.dados);
      setBbTipoIdade(bbTipoIdade?.data?.dados);
      setBbMaterialAnalisado(bbMaterialAnalisado?.data?.dados);
      setBbSigma(bbSigma?.data?.dados);
      setBbInterpretacao(bbInterpretacao?.data?.dados);
      setBbTipoIlustracao(tiposIlustracao?.data?.dados);
      setBbExtencaoIlustracao(extencoesIlustracao?.data?.dados);
      setBbMinerais(bbMinerais.data.dados || []);
    } catch (err) {
      console.log("Erro ao consultar bibliotecas: ", err);
    }
    setCarregamentoGlobal(false);
  }

  const colunasGeocron = useMemo(
    () => [
      {
        text: "Nome da amostra",
        width: "13%",
        datafield: "nomeAmostra",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          const geocronologia = geocronologias.find(
            (geocron) => geocron.id === rowdata.id
          );

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${
            geocronologia &&
            (geocronologia.informacoesAmostra.nomeAmostra || "")
          }</div>`;
        },
      },
      {
        text: "Nome da amostra segundo bibliografia",
        width: "20%",
        datafield: "nomeAmostraBibliografia",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          const geocronologia = geocronologias.find(
            (geocron) => geocron.id === rowdata.id
          );
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${
            geocronologia &&
            (geocronologia.informacoesAmostra.nomeAmostraBibliografia || "")
          }</div>`;
        },
      },
      {
        text: "Material analisado",
        width: "18%",
        datafield: "idMaterialAnalisado",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          const geocronologia = geocronologias.find(
            (geocron) => geocron.id === rowdata.id
          );
          const idMateriais =
            geocronologia?.idadeAssociadaAmostra.existentes.map(
              (item) => item.idMaterialAnalisado
            );
          const materiaisAnalisados = bbMaterialAnalisado
            .filter((materialAnalisado: { id: number }) =>
              idMateriais?.includes(materialAnalisado.id)
            )
            .map((materialAnalisado) => materialAnalisado.nome);
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${materiaisAnalisados?.join(
            ", "
          )}</div>`;
        },
      },
      {
        text: "Data de cadastro da análise",
        width: "15%",
        datafield: "dataUtimaAtualizacao",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          const geocronologia = geocronologias.find(
            (geocron) => geocron.id === rowdata.id
          );
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${geocronologia?.informacoesAmostra?.dataUltimaAtualizacao}</div>`;
        },
      },
      {
        text: "Status da revisão",
        datafield: "statusValidacao",
        editable: false,
        width: "15%",
        createwidget: createValidationColumn({
          ref: tabelaRef,
          method: "createwidget",
          hint: "Visualizar detalhes para correção",
        }),
        initwidget: createValidationColumn({
          ref: tabelaRef,
          method: "initwidget",
          hint: "Visualizar detalhes para correção",
        }),
      },
    ],
    [bbMaterialAnalisado, geocronologias]
  );
  const camposGeocron = useMemo(
    () => [
      { name: "id", type: "number", map: "id" },
      {
        name: "nomeAmostra",
        type: "string",
        map: "nomeAmostra",
      },
      {
        name: "nomeAmostraBibliografia",
        type: "string",
        map: "nomeAmostraBibliografia",
      },
      {
        name: "idMaterialAnalisado",
        type: "string",
        map: "idMaterialAnalisado",
      },
      {
        name: "dataUtimaAtualizacao",
        type: "string",
        map: "dataUtimaAtualizacao",
      },
      {
        name: "idStatusValidacao",
        type: "number",
        map: "idStatusValidacao",
      },
      {
        name: "statusValidacao",
        type: "String",
        map: "statusValidacao",
      },
      {
        name: "comentarioValidacao",
        type: "string",
        map: "comentarioValidacao",
      },
      {
        name: "comentarioRevisao",
        type: "string",
        map: "comentarioRevisao",
      },
    ],
    []
  );

  const dispararNotificacao = useCallback(
    (titulo: string, tipo: "success" | "error" = "success") => {
      dispatch(mostrarNotificacao(titulo, { tipo }, true));
    },
    [dispatch]
  );

  const consultarGeocronologiaPorAmostra = useCallback(async (idAmostra) => {
    setCarregamentoGlobal(true);
    const response = await consultarGeocronologiasPorIdAmostra(idAmostra);
    setGeoCronologias(response);
    setCarregamentoGlobal(false);
  }, []);

  const excluirGeocronologia = useCallback(
    async (e: React.FormEvent<HTMLButtonElement> & { value: number }) => {
      e.preventDefault();
      await deletarGeocronologiaPorId(e.value);

      dispararNotificacao("Geocronologia excluída com sucesso");

      await consultarGeocronologiaPorAmostra(idAmostra);
    },
    [consultarGeocronologiaPorAmostra, dispararNotificacao, idAmostra]
  );

  const form = useForm();

  useEffect(() => {
    form.mutators.setValue("geocronologias", geocronologias);
  }, [geocronologias, form]);

  const handleSubmit = useCallback(
    async ({
      exibidos,
      novosElementos,
    }: {
      exibidos: Geocronologia[];
      novosElementos: { novos: Geocronologia[]; editadosPorId: Geocronologia };
    }) => {
      let data: null | Geocronologia = null;
      if (novosElementos?.editadosPorId) {
        const ids = exibidos.map((exibido) => exibido.id);
        data =
          ids
            .map((id) => novosElementos?.editadosPorId?.[`id_${id}`])
            .find((item) => item !== undefined) || null;
      } else {
        data = novosElementos?.novos?.[0];
      }
      if (!data) return;
      setCarregamentoGlobal(true);

      await salvarGeocronologia(data)
        .then(async () => {
          await consultarGeocronologiaPorAmostra(idAmostra);
          dispararNotificacao("Geocronologia salva com sucesso");
        })
        .catch(() => setCarregamentoGlobal(false))
        .finally(() => setMostrarBotaoRevisao(true));

      setPodeEditar(true);
    },
    [consultarGeocronologiaPorAmostra, dispararNotificacao, idAmostra]
  );

  useEffect(() => {
    (async () => await consultarGeocronologiaPorAmostra(idAmostra))();
    compatibilizarCampos();
    return () => {};
  }, [consultarGeocronologiaPorAmostra, idAmostra]);

  useEffect(() => {
    if (
      !amostraSelecionada ||
      !Object.keys(amostraSelecionada).length ||
      !idAmostra ||
      !idUsuarioAtual ||
      !amostraOriginal
    )
      return;
    (async () => {
      const { altitude, idMetodoGeoposicionamento, idVisita } =
        amostraSelecionada;

      setValoresIniciaisEdicao({
        informacoesAmostra: {
          idClasseRocha: null,
          idSubClasseRocha: null,
          idPrefixoRocha: null,
          idComplementoRocha: null,
          idUnidadeLitoestratigrafica: null,
          nomeAmostraBibliografia: null,
          unidadeLitoestratigraficaBibliografia: null,
          idRocha: null,
          nomeAmostra: amostraOriginal,
          informacoesRelevantes: null,
          dataUltimaAtualizacao: dayjs(new Date()).format("DD/MM/YYYY"),
          nomeAssociacao: null,
          idAssociacao: null,
        },

        idAmostra,
        altitude,
        idMetodoGeoposicionamento,
        idVisita,
        idProjetoHistorico: idProjetoHistorico,
        idProjetoCorrente: idProjetoCorrente,
        idArea: idArea,
        idSubArea: idSubArea,
      });
    })();

    return () => {};
  }, [
    amostraSelecionada,
    idAmostra,
    idUsuarioAtual,
    amostraOriginal,
    idProjetoHistorico,
    idProjetoCorrente,
    idArea,
    idSubArea,
  ]);

  const podeEditarOuExcluirGeocronologias = (geocronologias) => {
    geocronologias.forEach((element) => {
      if (!podeEditarOuExcluir(element)) {
        element.permitirExclusao = false;
        element.permitirEdicao = false;
        return;
      }
      if (isChefeProjeto || idUsuarioAtual === element?.idResponsavel) {
        element.permitirExclusao = true;
        element.permitirEdicao = true;
      } else {
        element.permitirExclusao = false;
        element.permitirEdicao = false;
      }
    });
  };

  const enviarParaRevisao = useCallback(async () => {
    const rowsMarcadasParaEnvio = rowsSelecionadas.filter(
      (item) => item.checkboxMultiplaSelecao && podeEditarOuExcluir(item)
    );
    const qtdItensSelecionados = rowsMarcadasParaEnvio.length;

    if (!rowsMarcadasParaEnvio.length) {
      await swal({
        title: `É preciso selecionar ao menos uma atividade para revisão`,
        icon: "info",
      });
      return;
    }
    const result = await swal({
      title: `Enviar ${qtdItensSelecionados} atividade(s) para revisão? Edição e exclusão ficarão indisponíveis após o envio`,
      icon: "info",
      //@ts-ignore
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
      },
    });

    if (!result) return;

    const payloadRevisao = {
      idsSolicitados: rowsMarcadasParaEnvio.map((item) => item.id),
    };
    try {
      await Service("/atividades/enviaParaRevisao", VALIDACAO)
        .post(payloadRevisao)
        .then(() => {
          consultarGeocronologiaPorAmostra(idAmostra);
        });
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      await swal({
        title: `Erro`,
        text: errorResponse?.erros?.[0],
        icon: "error",
      });
    }
  }, [consultarGeocronologiaPorAmostra, idAmostra, rowsSelecionadas]);

  const podeEditarOuExcluir = (row) => {
    switch (row?.statusValidacao) {
      case "Aguardando revisão":
        return false;
      case "Aguardando validação":
        return false;
      case "Correção solicitada":
        return true;
      case "Corrigido":
        return true;

      case "Validado":
        return true;
      case "Atividade modificada":
        return true;
      case null:
      default:
        return true;
    }
  };
  const geraPdfGeocronologia = (e, tableRef, rowdata) => {
    const base = process.env.REACT_APP_API_URL_GEOCRON;
    const fichaId = rowdata?.rowData?.idExibir;
    gerarPdfRedirecionarNovaAba(fichaId, base);
  };

  return (
    <BlockUi blocking={carregamentoGlobal}>
      <Field name={"geocronologias"} subscription={{ value: true }}>
        {({ input: { value: geocronologias = {} } }) => {
          if (geocronologias) {
            podeEditarOuExcluirGeocronologias(geocronologias);
          }
          return (
            <SubForm
              tituloForm="Geocronologias cadastradas"
              nomeColunaCheckbox={"Disponibilizar para revisão"}
              tableRef={tabelaRef}
              permitirInsercao={true}
              exibirCardTabela={true}
              permitirExclusao={true}
              permitirEdicao={true}
              permitirVisualizacao={true}
              exibirBotaoInsercao={true}
              podeVisualizarEntidade={() => true}
              exibirTabelaSemRegistro={true}
              alternarBotoesPai={true}
              nome={"geocronologia"}
              onSubmit={handleSubmit}
              permitirGerarPdf={true}
              onBtnGerarPdfClick={geraPdfGeocronologia}
              onBtnDeleteClick={excluirGeocronologia}
              aoConfirmarVoltar={() => setMostrarBotaoRevisao(true)}
              onBtnVoltar={() => {
                setEstaEditando(false);
                setEstaVisualizando(false);
                setPodeInserir(true);
              }}
              onBtnAdicionar={() => {
                setMostrarBotaoRevisao(false);
                setPodeInserir(true);
                setDisabled(false);
              }}
              onBtnViewClick={() => {
                setDisabled(true);
                setEstaVisualizando(true);
                setPodeInserir(false);
              }}
              onBtnEditClick={() => {
                setDisabled(false);
                setMostrarBotaoRevisao(false);
                setEstaEditando(true);
                setPodeInserir(true);
              }}
              campos={camposGeocron}
              colunas={colunasGeocron}
              validarVoltar={!disabled}
              parametrosParaTableSource={{ width: "100%" }}
              itensTabela={geocronologias}
              elementos={
                {
                  existentes: geocronologias,
                } as any
              }
              labelBotaoConfirmar="Salvar"
              keepDirtyOnReinitialize={false}
              onOpen={onMount(true)}
              valoresIniciais={valoresIniciaisEdicao}
              colunaCheckboxGrid={true}
              rowDisponivelParaSelecao={podeEditarOuExcluir}
              aoSelecionarTodosCheckbox={setRowsSelecionadas as any}
              onCellvaluechanged={(e) => {
                const jqxGrid = e.owner;
                const rows = jqxGrid.getdisplayrows();
                setRowsSelecionadas(rows);
              }}
              renderForm={({ formProps }) => {
                return (
                  <>
                    <Field
                      component={HiddenField}
                      name="idProjetoCorrente"
                      value={idProjetoCorrente}
                    />
                    <Field
                      component={HiddenField}
                      name="idProjetoHistorico"
                      value={idProjetoCorrente}
                    />
                    <Field
                      component={HiddenField}
                      name="idArea"
                      value={idProjetoCorrente}
                    />
                    <Field
                      component={HiddenField}
                      name="idSubArea"
                      value={idProjetoCorrente}
                    />
                    <SubFormAmostra
                      amostraSelecionada={amostraSelecionada}
                      estaEditando={estaEditando}
                      formRef={formProps}
                      disabled={disabled}
                      estaVisualizando={estaVisualizando}
                      bbMinerais={bbMinerais}
                      coletores={coletores}
                      isChefeProjeto={isChefeProjeto}
                      idUsuarioAtual={idUsuarioAtual}
                    />
                    <SubFormIdadeAssociadaAmostra
                      formProps={formProps}
                      bbSigma={bbSigma}
                      bbTipoIdade={bbTipoIdade}
                      bbInterpretacao={bbInterpretacao}
                      bbSistemaIsotopico={bbSistemasIsotopicos}
                      bbTecnicaAnalitica={bbTecnicaAnalitica}
                      bbMaterialAnalisado={bbMaterialAnalisado}
                      bbIdadeMilhoesDeAnos={[]}
                      disabled={disabled}
                      permitirInsercao={podeInserir}
                    />
                    <SubFormDetalhesIsocronas
                      formRef={formProps}
                      disabled={disabled}
                      permitirInsercao={podeInserir}
                      idAmostra={idAmostra}
                    />
                    <SubFormDetalhesAquecimento
                      formRef={formProps}
                      disabled={disabled}
                      permitirInsercao={podeInserir}
                    />
                    <MaterialSuplementar
                      amostraSelecionada={amostraSelecionada}
                      disabled={disabled}
                      extencoesIlustracao={bbExtencaoIlustracao}
                      tiposIlustracao={bbTipoIlustracao}
                      permitirInsercao={podeInserir}
                    />
                  </>
                );
              }}
            />
          );
        }}
      </Field>
      {podeInserir && (
        mostrarBotaoRevisao && permitirEnviarParaRevisao && (
          <FocusableButton
            className="float-right m-1"
            style={{ fontSize: "smaller" }}
            onClick={enviarParaRevisao}
          >
            Enviar para revisão
          </FocusableButton>
        )
      )}

      
    </BlockUi>
  );
};

export default memo(Geocronologias);
